.header-nav-link {
  color: #000;
  font-size: 1.25rem !important;
  margin: 0 .5rem 0 .5rem !important;;
  cursor: pointer;
  position: relative; /* Required for absolute positioning of ::after pseudo-element */
}

.header-nav-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: transparent;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.header-nav-link:hover::after {
  background-color: #000;
  transform: scaleX(1);
}
