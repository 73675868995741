.section {
  display: flex;
  align-items: center;
  flex-direction: column;

  &-even {
    background-color: #ffffff;
  }

  &-odd {
    background-color: #E7F0FF;
  }

  &-body {
    display: flex;
    justify-content: center;
  }
}

#section-0 {
  height: 50rem;

  background-image: url('../../../../public/hk-background.webp'); /* Replace 'your-image.jpg' with the path to your image */
  background-size: cover; /* This will make the image cover the entire container without resizing */
  background-position: center; /* This will center the image */
  background-repeat: no-repeat; /* This will prevent the image from repeating */

  align-items: center;
  justify-content: start;
  flex-direction: column;

  .header {
    font-family: "RobotoSlab", serif;
    color: #000;

    &-main {
      margin-top: 3rem;
      font-size: 2.25rem;
    }

    &-sub {
      font-size: 1.5rem;
    }
  }
}